import { checkIfMefuUser } from "components/LSRevamp/utils"
import { isMobile, osName } from "react-device-detect"
import timeZoneCityToCountry from "tz-cities-to-countries.json"
import { getUserDeviceName } from "utils/browserUtils"
import { getCookie } from "utils/cookie-utiils"
import { getPlatform } from "utils/events-utils"
import { getUtmData } from "utils/utm_utils"

declare global {
  interface Window {
    analytics: any
  }
}

declare global {
  interface Document {
    document: any
  }
}

const timestamp = "NA"

const utmDetails = getUtmData(null)
const userId = getCookie(null, "user_id")

let userCountry: string

const gaClientId = getCookie(null, "_ga")

if (Intl) {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const tzArr = userTimeZone.split("/")
  const userCity = tzArr[tzArr.length - 1]
  userCountry =
    timeZoneCityToCountry[userCity as keyof typeof timeZoneCityToCountry]
}

export const trackPage = (name: string, properties?: any) => {
  const eventName = "Page Viewed"
  const isMefuUser = checkIfMefuUser()

  const props = {
    pageName: name || "NA",
    pageUrl: window.location.href || "NA",
    utmCampaign: utmDetails?.utmCampaign || "NA",
    gclid: utmDetails?.gclid || "NA",
    adName: utmDetails?.adName || "NA",
    utmSource: utmDetails?.utmSource || "NA",
    utmTerm: utmDetails?.utmTerm || "NA",
    adId: utmDetails?.adId || "NA",
    platform: isMobile ? "Mweb" : "WEB",
    timestamp: timestamp,
    ShownSlots: JSON.stringify(properties?.slots) || "NA",
    referrer: document?.referrer || "NA",
    screenHeight: window?.screen.height,
    screenWidth: window?.screen.width,
    OS: osName,
    stage: properties?.stage || "NA",
    eventId: properties?.eventId || "NA",
    country: userCountry || "N/A",
    payType: properties?.payType || "NA",
    userId: properties?.userId || "N/A",
    gaClientId: gaClientId || "NA",
    leadSource: properties?.leadSource || "NA",
    flowType: properties?.flowType || "NA",
    ...properties,
  }

  const userPayload = {
    isMefuUser: isMefuUser ?? "N/A",
    ...properties?.slotInfo,
  }

  if (window.analytics) {
    window.analytics.track(eventName, props)
    window.analytics.identify(properties?.userId, userPayload)
  }
}

export const trackPageExit = (name: string, properties?: any) => {
  const eventName = "Page Exit"

  const props = {
    pageName: name || "NA",
    pageUrl: window.location.href || "NA",
    utmCampaign: utmDetails?.utmCampaign || "NA",
    utmSource: utmDetails?.utmSource || "NA",
    gclid: utmDetails?.gclid || "NA",
    adName: utmDetails?.adName || "NA",
    utmTerm: utmDetails?.utmTerm || "NA",
    adId: utmDetails?.adId || "NA",
    platform: isMobile ? "Mweb" : "WEB",
    timestamp: timestamp,
    referrer: document?.referrer || "NA",
    screenHeight: window?.screen.height,
    screenWidth: window?.screen.width,
    OS: osName,
    eventId: properties?.eventId || "NA",
    gaClientId: gaClientId || "NA",
  }
  if (window.analytics) {
    window.analytics.track(eventName, props)
  }
}

export const trackPageV2 = (name: string, properties?: any) => {
  const eventName = "Page Viewed"

  const props = {
    pageName: name || "NA",
    pageUrl: window.location.href || "NA",
    utmCampaign: utmDetails?.utmCampaign || "NA",
    utmSource: utmDetails?.utmSource || "NA",
    utmTerm: utmDetails?.utmTerm || "NA",
    gclid: utmDetails?.gclid || "NA",
    adName: utmDetails?.adName || "NA",
    adId: utmDetails?.adId || "NA",
    utmMedium: utmDetails?.utmMedium || "NA",
    platform: isMobile ? "Mweb" : "WEB",
    timestamp: timestamp,
    referrer: document?.referrer || "NA",
    screenHeight: window?.screen.height,
    screenWidth: window?.screen.width,
    OS: osName,
    eventId: properties?.eventId || "NA",
    Platform: getPlatform(),
    country: userCountry || "N/A",
    ...properties,
    gaClientId: gaClientId || "NA",
  }
  if (window.analytics) {
    window.analytics.track(eventName, props)
  }
}

export const trackPageViewWithContent = (name: string, props: any) => {
  const eventName = "Page Viewed"
  const data = {
    pageName: name || "NA",
    pageUrl: window.location.href || "NA",
    contentName: props?.contentName || "NA",
    contentFormat: props.contentFormat || "NA",
    utmCampaign: utmDetails?.utmCampaign || "NA",
    gclid: utmDetails?.gclid || "NA",
    adName: utmDetails?.adName || "NA",
    utmSource: utmDetails?.utmSource || "NA",
    utmTerm: utmDetails?.utmTerm || "NA",
    adId: utmDetails?.adId || "NA",
    platform: isMobile ? "Mweb" : "WEB",
    timestamp: timestamp,
    screenHeight: window?.screen.height,
    screenWidth: window?.screen.width,
    OS: osName,
    modalName: props?.modalName,
    applicationStage: props?.applicationStage,
    Platform: getPlatform(),
    screenType: props.screenType || "NA",
    gaClientId: gaClientId || "NA",
  }
  const userPayload = {
    pageName: window.location.href || "NA",
    utmCampaign: props.utmCampaign || "NA",
    gclid: utmDetails?.gclid || "NA",
    adName: utmDetails?.adName || "NA",
    utmSource: props?.utmSource || "NA",
    utmTerm: props?.utmTerm || "NA",
    adId: props?.adId || "NA",
    flowType: props.flowType || "NA",
    subscribedToWhatsapp: true,
    userId: props?.userId || "NA",
    phone: props?.phone ? props?.phone : undefined,
    timestamp: timestamp,
    country: props?.country || userCountry || "NA",
  }
  if (window.analytics) {
    window.analytics.track(eventName, data)
    window.analytics.identify(props?.userId, userPayload)
  }
}

export const trackClick = async (name: any, props: any, eventType?: string) => {
  const eventName = eventType ?? "Click"
  const isMefuUser = checkIfMefuUser()
  const data = {
    pageName: name || "NA",
    widgetFormat: props.widgetFormat || "NA",
    widgetName: props.widgetName || "NA",
    contentName: props.contentName || "NA",
    contentFormat: props.contentFormat || "NA",
    utmCampaign: utmDetails?.utmCampaign || "NA",
    utmSource: utmDetails?.utmSource || "NA",
    utmTerm: utmDetails?.utmTerm || "NA",
    adId: utmDetails?.adId || "NA",
    gclid: utmDetails?.gclid || "NA",
    adName: utmDetails?.adName || "NA",
    timestamp: timestamp || "NA",
    from: props?.from || "NA",
    selectedSlot: props?.selectedSlot || "NA",
    phone: props?.phone || "NA",
    cardNumber: props?.cardNumber || "NA",
    platform: isMobile ? "Mweb" : "WEB",
    sectionName: props?.sectionName || "NA",
    eventCategory: props?.eventCategory || "NA",
    screenHeight: window?.screen.height,
    screenWidth: window?.screen.width,
    OS: osName,
    applicationStage: props?.applicationStage,
    howLeapHelp: props?.howLeapHelp || "NA",
    passportStatus: props?.passportStatus || "NA",
    counsellorRecommendationId: props?.counsellorRecommendationId || "NA",
    payType: props?.payType || "NA",
    error: props?.error || "NA",
    rating: props?.rating,
    reason: props?.reason,
    collegeName: props?.collegeName || "NA",
    gaClientId: gaClientId || "NA",
    preferencesMatchCount: props?.preferencesMatchCount || "NA",
    pageUrl: window.location.href || "NA",
    utmMedium: utmDetails?.utmMedium || "NA",
    pageCategory: props?.pageCategory || "NA",
    stage: props?.stage || "NA",
    Platform: getPlatform(),
    ...props,
  }

  const userPayload = {
    isMefuUser: isMefuUser ?? "N/A",
  }

  if (window.analytics) {
    await window.analytics.track(eventName, data)
    await window.analytics.identify(props.userId, userPayload)
  }
}

export const trackIdentity = (eventName: string, props: any) => {
  if (window.analytics) {
    window.analytics.identify(eventName, props)
  }
}

export const trackIdentityV2 = async (eventName: string, props: any) => {
  if (window.analytics) {
    return fallBackAnalytics(
      new Promise((resolve) => {
        window.analytics.identify(eventName, props, {}, () => {
          resolve(true)
        })
      }),
    )
  }
}

export const trackEvent = (eventName: string, props: any) => {
  if (window.analytics) {
    window.analytics.track(eventName, {
      ...props,
      gaClientId: gaClientId || "NA",
    })
  }
}
export const trackIeltsEvent = (eventName: string, props: any) => {
  props = {
    ...props,
    utmCampaign: utmDetails?.utmCampaign || "NA",
    utmSource: utmDetails?.utmSource || "NA",
    utmTerm: utmDetails?.utmTerm || "NA",
    adId: utmDetails?.adId || "NA",
    gclid: utmDetails?.gclid || "NA",
    adName: utmDetails?.adName || "NA",
    referrer: document?.referrer || "NA",
    Platform: getPlatform(),
    gaClientId: gaClientId || "NA",
  }
  if (window.analytics) {
    window.analytics.track(eventName, props)
  }
}

export const trackPayment = (eventName: string, props: any) => {
  if (window.analytics) {
    window.analytics.track(eventName, {
      ...props,
      gaClientId: gaClientId || "NA",
    })
  }
}

export const trackSignup = async (eventName: string, props: any) => {
  if (window.analytics) {
    const eventPayload = {
      pageName: window.location.href || "NA",
      authType: props.authType || "NA",
      utmCampaign: props.utmCampaign || "NA",
      utmSource: props?.utmSource || "NA",
      utmTerm: props?.utmTerm || "NA",
      gclid: utmDetails?.gclid || "NA",
      adName: utmDetails?.adName || "NA",
      adId: props?.adId || "NA",
      flowType: props.flowType || "NA",
      subscribedToWhatsapp: true,
      timestamp: timestamp,
      country: props?.country || "NA",
      Platform: getPlatform(),
      gaClientId: gaClientId || "NA",
      ...props
    }
    const userPayload = {
      pageName: window.location.href || "NA",
      utmCampaign: props.utmCampaign || "NA",
      utmSource: props?.utmSource || "NA",
      utmTerm: props?.utmTerm || "NA",
      gclid: utmDetails?.gclid || "NA",
      adName: utmDetails?.adName || "NA",
      adId: props?.adId || "NA",
      flowType: props.flowType || "NA",
      subscribedToWhatsapp: true,
      userId: props?.userId || "NA",
      phone: props?.phone ? props?.phone : undefined,
      timestamp: timestamp,
      country: props?.country || "NA",
      ...props
    }
    await window.analytics.identify(props.userId, userPayload)
    await window.analytics.track(eventName, eventPayload)
  }
}

export const trackForm = async (eventName: string, props: any) => {
  if (window.analytics) {
    const eventPayload = {
      formType: props?.formType || "NA",
      formId: props?.formId || "NA",
      utmCampaign: utmDetails?.utmCampaign || "NA",
      utmSource: utmDetails?.utmSource || "NA",
      utmTerm: utmDetails?.utmTerm || "NA",
      adId: utmDetails?.adId || "NA",
      gclid: utmDetails?.gclid || "NA",
      adName: utmDetails?.adName || "NA",
      timestamp: timestamp,
      phone: props?.phone || "NA",
      name: props?.name || "NA",
      Platform: getPlatform(),
      howLeapHelp: props?.howLeapHelp || "NA",
      pageUrl: window?.location?.href || "NA",
      gaClientId: gaClientId || "NA",
      ...props
    }

    const userPayload = {
      admitStatus:
        props?.admitDetails?.admitStatus || props?.admitStatus || "NA",
      college: props?.college || "NA",
      consultant: props?.consultant || "NA",
      helpForAdmit: props?.helpForAdmit || "NA",
      email: props?.email ? props?.email : undefined,
      gradeMetric: props?.gradeMetric || "NA",
      grades: props?.grades || "NA",
      highestLevelEducation: props?.highestLevelEducation || "NA",
      ieltsStatus: props?.ieltsStatus || "NA",
      journeyStatus: props?.journeyStatus || "NA",
      payOfExpenses: props?.payOfExpenses || "NA",
      preferredCountry: props?.preferredCountry || "NA",
      preferredIntake: props?.preferredIntake || "NA",
      preferredProgram: props?.preferredProgram || "NA",
      timestamp: timestamp,
      ieltsType: props?.ieltsType || "NA",
      ieltsPurpose: props?.ieltsPurpose || "NA",
      ieltsMonth: props?.ieltsMonth || "NA",
      ieltsYear: props?.ieltsYear || "NA",
      phone: props?.phone ? props?.phone : undefined,
      name: props?.name ? props?.name : undefined,
      howLeapHelp: props?.howLeapHelp || "NA",
      pageUrl: window?.location?.href || "NA",
      ...props
    }
    window.analytics.identify(userId, userPayload)
    window.analytics.track(eventName, eventPayload)
  }
}

export const trackReferral = (
  eventName: string,
  props: any,
  userId: string,
) => {
  if (window.analytics) {
    const eventPayload = {
      formId: props?.formId || "NA",
      createdAt: new Date(),
      referrerName: props?.referrerName || "NA",
      referrerPhone: props?.referrerPhone || "NA",
      referrerUserId: props?.referrerUserId || "NA",
      refereeName: props?.refereeName || "NA",
      refereePhone: props?.refereePhone || "NA",
      refereeUserId: props?.refereeUserId || "NA",
      gaClientId: gaClientId || "NA",
    }
    window.analytics.track(eventName, eventPayload, { userId })
    window.analytics.identify(userId, {})
  }
}

export const trackProduct = (eventName: string, props: any) => {
  if (window.analytics) {
    const eventPayload = {
      ...props,
      timestamp: timestamp,
      gaClientId: gaClientId || "NA",
    }
    const userPayload = {
      eventCategory: props?.eventCategory || "NA",
      productCategory: props?.productCategory || "NA",
      cohorts: props?.cohorts || "NA",
    }
    window.analytics.identify(userId, userPayload)
    window.analytics.track(eventName, eventPayload)
  }
}

export const trackProductFormAsync = (eventName: string, props: any) => {
  return new Promise((resolve) => {
    if (window.analytics) {
      const eventPayload = {
        ...props,
        timestamp: timestamp,
        gaClientId: gaClientId || "NA",
      }
      const userPayload = {
        eventCategory: props?.eventCategory || "NA",
        productCategory: props?.productCategory || "NA",
        cohorts: props?.cohorts || "NA",
      }
      window.analytics.identify(userId, userPayload)
      window.analytics.track(eventName, eventPayload)
      resolve(true)
    }
  })
}

export const trackOnboardingProduct = (eventName: string, props: any) => {
  if (window.analytics) {
    const eventPayload = {
      ...props,
      timestamp: timestamp,
      gaClientId: gaClientId || "NA",
    }
    const userPayload = {}
    window.analytics.identify(userId, userPayload)
    window.analytics.track(eventName, eventPayload)
  }
}

export const segmentReset = () => {
  if (window.analytics) {
    window.analytics.reset()
  }
}

export const trackIeltsClick = (name: any, props: any) => {
  const eventName = "Click"
  //  // add a new field in the array below which is passed in a set
  const eventFields = [
    "pageName",
    "pageNumber",
    "pageContent",
    "widgetFormat",
    "widgetName",
    "contentName",
    "contentFormat",
    "utmCampaign",
    "utmSource",
    "utmTerm",
    "adId",
    "timestamp",
    "eventId",
    "eventID2",
    "QuizScore",
    "eventCategory",
    "teacher",
    "startDate",
    "endDate",
    "title",
    "startTime",
    "endTime",
    "eventLink",
    "referrer",
    "sectionName",
    "filterName",
    "offerPrice",
    "Rewardstatus",
    "RewardDay",
    "RewardName",
    "Status",
    "contentPage",
    "product",
    "buttonName",
    "type",
    "EducatorName",
    "platform",
    "pageUrl",
    "bundleTitle",
    "bundleType",
    "counsellingStage",
    "course",
    "pageType",
    "recordingType",
    "filters",
    "leadSource",
    "flowType",
    "abVariant",
  ]
  let eventData: any = eventFields.reduce((accumulator: any, currentValue) => {
    if (props[currentValue] || typeof props[currentValue] === "number") {
      accumulator[currentValue] = props[currentValue]
    }
    return accumulator
  }, {})

  const defaultFields = {
    pageName: name || "NA",
    referrer: document?.referrer || "NA",
    utmCampaign: utmDetails?.utmCampaign || "NA",
    utmSource: utmDetails?.utmSource || "NA",
    utmTerm: utmDetails?.utmTerm || "NA",
    adId: utmDetails?.adId || "NA",
    gclid: utmDetails?.gclid || "NA",
    adName: utmDetails?.adName || "NA",
    timestamp: timestamp || "NA",
    screenHeight: window?.screen.height,
    screenWidth: window?.screen.width,
    OS: osName,
    Platform: getPlatform(),
    pageUrl: window.location.href,
  }
  eventData = { ...eventData, ...defaultFields, gaClientId: gaClientId || "NA" }
  if (window.analytics) {
    window.analytics.track(eventName, eventData)
  }
}

export const trackEventsClick = (name: any, props: any) => {
  const eventName = "Click"
  const data = {
    pageName: name || "NA",
    widgetFormat: props?.widgetFormat || "NA",
    widgetName: props?.widgetName || "NA",
    contentName: props?.contentName || "NA",
    contentFormat: props?.contentFormat || "NA",
    utmCampaign: utmDetails?.utmCampaign || "NA",
    utmSource: utmDetails?.utmSource || "NA",
    utmTerm: utmDetails?.utmTerm || "NA",
    adId: utmDetails?.adId || "NA",
    gclid: utmDetails?.gclid || "NA",
    adName: utmDetails?.adName || "NA",
    timestamp: timestamp || "NA",
    eventId: `${props?.eventId || "NA"}`,
    eventTags: props?.tags || "NA",
    eventCTA: props?.cta || "NA",
    referrer: document?.referrer || "NA",
    currentPage: window?.location?.pathname || "NA",
    screenHeight: window?.screen.height,
    screenWidth: window?.screen.width,
    OS: osName,
    Platform: getPlatform(),
    gaClientId: gaClientId || "NA",
  }

  if (window.analytics) {
    window.analytics.track(eventName, data)
  }
}

export const trackIeltsEventAttend = (props: any) => {
  const eventName = "Event Attended"
  const data = {
    eventId: `${props?.eventId || "NA"}`,
    eventCategory: props?.eventCategory || "NA",
    teacher: props?.teacher || "NA",
    meetingDate: props?.meetingDate || "NA",
    title: props?.title || "NA",
    meetingTime: props?.meetingTime || "NA",
    eventLink: props?.eventLink || "NA",
    batchDay: props?.batchDay || "NA",
    Platform: getPlatform(),
    gaClientId: gaClientId || "NA",
  }

  if (window.analytics) {
    window.analytics.track(eventName, data)
  }
}

export const trackIeltsForm = async (eventName: string, props: any) => {
  let email = undefined
  if (props.email) email = props.email
  else if (props.emailId) email = props.emailId

  if (window.analytics) {
    const eventPayload = {
      formType: props?.formType || "NA",
      eventId: `${props?.eventId || "NA"}`,
      pageName: props?.pageName || "NA",
      widgetFormat: props?.widgetFormat || "NA",
      widgetName: props?.widgetName || "NA",
      contentName: props?.contentName || "NA",
      contentFormat: props?.contentFormat || "NA",
      utmCampaign: utmDetails?.utmCampaign || "NA",
      utmSource: utmDetails?.utmSource || "NA",
      utmTerm: utmDetails?.utmTerm || "NA",
      adId: utmDetails?.adId || "NA",
      gclid: utmDetails?.gclid || "NA",
      adName: utmDetails?.adName || "NA",
      rating: `${props.rating || "NA"}`,
      timestamp: timestamp,
      referrer: document?.referrer || "NA",
      Platform: getPlatform(),
      gaClientId: gaClientId || "NA",
    }

    const userPayload = {
      college: props?.college || "NA",
      email,
      highestLevelEducation: props?.highestLevelEducation || "NA",
      ieltsStatus: props?.ieltsStatus || "NA",
      journeyStatus: props?.journeyStatus || "NA",
      payOfExpenses: props?.payOfExpenses || "NA",
      preferredCountry: props?.preferredCountry || "NA",
      preferredIntake: props?.preferredIntake || "NA",
      preferredProgram: props?.preferredProgram || "NA",
      timestamp: timestamp,
      currentEnglishLevel: props?.currentEnglishLevel || "NA",
      ieltsExamMonth: props?.ieltsExamMonth || "NA",
      prefferedTimeSlot: props?.prefferedTimeSlot || "NA",
    }
    window.analytics.identify(userId, userPayload)
    window.analytics.track(eventName, eventPayload)
  }
}
export const trackIeltsFormAsync = (
  eventName: string,
  props: any,
): Promise<any> => {
  return new Promise(async (resolve) => {
    let email = undefined
    if (props.email) email = props.email
    else if (props.emailId) email = props.emailId
    const eventPayload = {
      formType: props?.formType || "NA",
      eventId: `${props?.eventId || "NA"}`,
      pageName: props?.pageName || "NA",
      widgetFormat: props?.widgetFormat || "NA",
      widgetName: props?.widgetName || "NA",
      contentName: props?.contentName || "NA",
      contentFormat: props?.contentFormat || "NA",
      utmCampaign: utmDetails?.utmCampaign || "NA",
      utmSource: utmDetails?.utmSource || "NA",
      utmTerm: utmDetails?.utmTerm || "NA",
      gclid: utmDetails?.gclid || "NA",
      adName: utmDetails?.adName || "NA",
      adId: utmDetails?.adId || "NA",
      rating: `${props.rating || "NA"}`,
      timestamp: timestamp,
      referrer: document?.referrer || "NA",
      Platform: getPlatform(),
      formId: props.formId || "NA",
      leadSource: props.leadSource || "NA",
      gaClientId: gaClientId || "NA",
      ...props,
    }

    const userPayload = {
      college: props?.college || "NA",
      email,
      highestLevelEducation: props?.highestLevelEducation || "NA",
      ieltsStatus: props?.ieltsStatus || "NA",
      journeyStatus: props?.journeyStatus || "NA",
      payOfExpenses: props?.payOfExpenses || "NA",
      preferredCountry: props?.preferredCountry || "NA",
      preferredIntake: props?.preferredIntake || "NA",
      preferredProgram: props?.preferredProgram || "NA",
      timestamp: timestamp,
      currentEnglishLevel: props?.currentEnglishLevel || "NA",
      ieltsExamMonth: props?.ieltsExamMonth || "NA",
      prefferedTimeSlot: props?.prefferedTimeSlot || "NA",
      name: props?.name ? props?.name : undefined,
      ieltsExamType: props?.ieltsExamType || "NA",
      whatCareerStage: props?.whatCareerStage || "NA",
      whenIelts: props?.whenIelts || "NA",
      phone: props?.phone ? props?.phone : undefined,
    }
    if (window.analytics) {
      await Promise.all([
        trackIdentityV2(userId, userPayload),
        trackEventAsync(eventName, eventPayload),
      ])
      resolve(true)
    }
  })
}

export const trackIeltsPayment = (props: any) => {
  if (window.analytics) {
    window.analytics.track("Payment Successful", {
      ...props,
      utm_campaign: utmDetails?.utmCampaign || "NA",
      utm_Source: utmDetails?.utmSource || "NA",
      utm_term: utmDetails?.utmTerm || "NA",
      gclid: utmDetails?.gclid || "NA",
      adName: utmDetails?.adName || "NA",
      ad_id: utmDetails?.adId || "NA",
      timestamp: timestamp,
      referrer: document?.referrer || "NA",
      Platform: getPlatform(),
      gaClientId: gaClientId || "NA",
    })
  }
}

export const trackIeltsError = (props: any) => {
  const eventName = "Error"
  const data = {
    eventId: `${props?.eventId || "NA"}`,
    eventCategory: props?.eventCategory || "NA",
    meetingDate: props?.meetingDate || "NA",
    title: props?.title || "NA",
    teacher: props?.teacher || "NA",
    meetingTime: props?.meetingTime || "NA",
    eventLink: props?.eventLink || "NA",
    errorCategory: props?.errorCategory || "NA",
    errorMessage: props?.errorMessage || "NA",
    Platform: getPlatform(),
    gaClientId: gaClientId || "NA",
  }

  if (window.analytics) {
    window.analytics.track(eventName, data)
  }
}

export const trackFileUpload = (name: string, props: any) => {
  const eventName = "Document Uploaded"
  const data = {
    pageName: name || "NA",
    fileType: props?.fileType || "NA",
    fileCategory: props?.fileCategory || "NA",
    fileName: props?.fileName || "NA",
    uploadSource: props?.uploadSource || "NA",
    utmCampaign: utmDetails?.utmCampaign || "NA",
    utmSource: utmDetails?.utmSource || "NA",
    utmTerm: utmDetails?.utmTerm || "NA",
    adId: utmDetails?.adId || "NA",
    gclid: utmDetails?.gclid || "NA",
    adName: utmDetails?.adName || "NA",
    referrer: document?.referrer || "NA",
    gaClientId: gaClientId || "NA",
  }

  if (window.analytics) {
    window.analytics.track(eventName, data)
  }
}

export const trackVideo = (props: any) => {
  const eventName = "Watch Video"
  const data = {
    videoName: props.videoName || "NA",
    videoId: props.videoId || "NA",
    videoUrl: props.videoUrl || "NA",
    watchDuration: props.watchDuration || "NA",
    utmCampaign: utmDetails?.utmCampaign || "NA",
    gclid: utmDetails?.gclid || "NA",
    adName: utmDetails?.adName || "NA",
    utmSource: utmDetails?.utmSource || "NA",
    utmTerm: utmDetails?.utmTerm || "NA",
    adId: utmDetails?.adId || "NA",
    timestamp: timestamp || "NA",
    gaClientId: gaClientId || "NA",
  }

  if (window.analytics) {
    window.analytics.track(eventName, data)
  }
}

export const trackCalendarMeetingEvent = (name: any, props: any) => {
  const eventName = props.eventName || ""
  const data = {
    EventCategory: "Office Hour",
    EventID: "NA",
    Counsellor: props.CounsellorName,
    Speaker: "NA",
    Host: "NA",
    EventDate: props.date,
    EventTime: props.time,
    utmCampaign: utmDetails?.utmCampaign || "NA",
    gclid: utmDetails?.gclid || "NA",
    adName: utmDetails?.adName || "NA",
    utmSource: utmDetails?.utmSource || "NA",
    utmTerm: utmDetails?.utmTerm || "NA",
    adId: utmDetails?.adId || "NA",
    gaClientId: gaClientId || "NA",
  }

  if (window.analytics) {
    window.analytics.track(eventName, data)
  }
}

export const trackEventAsync = async (name: any, props?: any) => {
  const eventName = name || ""
  if (window.analytics) {
    return fallBackAnalytics(
      new Promise((resolve) => {
        window.analytics.track(
          eventName,
          {
            ...props,
            utmCampaign: utmDetails?.utmCampaign || "NA",
            utmSource: utmDetails?.utmSource || "NA",
            utmTerm: utmDetails?.utmTerm || "NA",
            gclid: utmDetails?.gclid || "NA",
            adName: utmDetails?.adName || "NA",
            utmMedium: utmDetails?.utmMedium || "NA",
            adId: utmDetails?.adId || "NA",
            Platform: getPlatform(),
            platform: getPlatform(),
            gaClientId: gaClientId || "NA",
          },
          {},
          () => {
            resolve(true)
          },
        )
      }),
    )
  }
}

export const slotShown = (properties?: any) => {
  const eventName = "Slots Shown"

  const props = {
    pageName: "Slots Shown",
    pageUrl: window.location.href || "NA",
    utmCampaign: utmDetails?.utmCampaign || "NA",
    utmSource: utmDetails?.utmSource || "NA",
    utmTerm: utmDetails?.utmTerm || "NA",
    adId: utmDetails?.adId || "NA",
    platform: isMobile ? "Mweb" : "WEB",
    timestamp: timestamp,
    shownSlots: properties?.slots || "NA",
    slotCount: properties?.slotCount || "NA",
    referrer: document?.referrer || "NA",
    screenHeight: window?.screen.height,
    screenWidth: window?.screen.width,
    OS: osName,
    eventId: properties?.eventId || "NA",
    country: userCountry || "N/A",
    ...properties,
    gaClientId: gaClientId || "NA",
  }

  if (window.analytics) {
    window.analytics.track(eventName, props)
    window.analytics.identify(properties?.userId, {})
  }
}

export const trackUserDevice = async (): Promise<undefined> => {
  try {
    if (window.analytics) {
      await getUserDeviceName().then(
        (deviceName) =>
          deviceName &&
          new Promise((resolve) => {
            window.analytics.identify(
              userId,
              { deviceModel: deviceName },
              {},
              () => {
                resolve(true)
              },
            )
          }),
      )
    }
  } catch (e) {
    console.log("err", e)
  }
  return
}

const fallBackAnalytics = (cb: Promise<boolean>) =>
  new Promise((resolve) => {
    const timer = setTimeout(() => {
      resolve(true)
      clearTimeout(timer)
    }, 900)
    cb.then(() => {
      resolve(true)
      clearTimeout(timer)
    })
  })
