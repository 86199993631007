import { WorkshopSchema } from "Interfaces/reducerTypes/IeltsPlusSchema"
import { NextBatchPriceAndTiming } from "Interfaces/reducerTypes/UpcomingIeltsBatches"
import { NextPageContext } from "next"
import {
  AllIeltsBatches,
  BatchesBannerCarouselSchema,
} from "components/Ielts/UpcomingIeltsBatches/ViewAllBatches/DataMapper/interface"

export const UpcomingType = {
  UPCOMING_IELTS_FETCH_REQUESTED: "UPCOMING_IELTS_FETCH_REQUESTED",
  UPCOMING_IELTS_FETCH_SUCCESS: "UPCOMING_IELTS_FETCH_SUCCESS",
  UPCOMING_IELTS_FETCH_FAIL: "UPCOMING_IELTS_FETCH_FAIL",
  UPCOMING_IELTS_FETCH_CLIENT_REQUESTED:
    "UPCOMING_IELTS_FETCH_CLIENT_REQUESTED",
  IELTS_FOUNDATION_BATCH_FETCH_REQUESTED:
    "IELTS_FOUNDATION_BATCH_FETCH_REQUESTED",
  IELTS_FOUNDATION_BATCH_FETCH_SUCCESS: "IELTS_FOUNDATION_BATCH_FETCH_SUCCESS",
  IELTS_PLUS_BATCH_FETCH_SUCCESS: "IELTS_PLUS_BATCH_FETCH_SUCCESS",
  IELTS_PREMIUM_BATCH_FETCH_SUCCESS: "IELTS_PREMIUM_BATCH_FETCH_SUCCESS",
  NUDGE_FETCH_SUCCESS: "NUDGE_FETCH_SUCCESS",
  TOGGLE_UPCOMING_IELTS_BATCHES_MODAL: "TOGGLE_UPCOMING_IELTS_BATCHES_MODAL",
  FETCH_GET_UPCOMING_BATCH_TIME_AND_PRICE:
    "FETCH_GET_UPCOMING_BATCH_TIME_AND_PRICE",
  FETCH_GET_UPCOMING_BATCH_TIME_AND_PRICE_SUCCESS:
    "FETCH_GET_UPCOMING_BATCH_TIME_AND_PRICE_SUCCESS",

  FETCH_IELTS_BATCHES: "FETCH_IELTS_BATCHES",
  FETCH_PTE_BATCHES: "FETCH_PTE_BATCHES",
  SET_ALL_IELTS_BATCHES: "SET_ALL_IELTS_BATCHES",
  FETCH_BATCHES_BANNER: "FETCH_BATCHES_BANNER",
  SET_BATCHES_BANNER_DATA: "SET_BATCHES_BANNER_DATA",
  TOGGLE_OFFER_MODAL: "TOGGLE_OFFER_MODAL",
}

const fetchUpcomingIeltsBatch = (payload: NextPageContext) => ({
  type: UpcomingType.UPCOMING_IELTS_FETCH_REQUESTED,
  payload,
})

const fetchUpcomingIeltsBatchClient = () => ({
  type: UpcomingType.UPCOMING_IELTS_FETCH_CLIENT_REQUESTED,
})

const fetchIeltsFoundationBatchRequest = () => ({
  type: UpcomingType.IELTS_FOUNDATION_BATCH_FETCH_REQUESTED,
})

const fetchIeltsFoundationBatchSuccess = (payload: any) => ({
  type: UpcomingType.IELTS_FOUNDATION_BATCH_FETCH_SUCCESS,
  payload,
})

const fetchIeltsPlusBatchSuccess = (payload: any) => ({
  type: UpcomingType.IELTS_PLUS_BATCH_FETCH_SUCCESS,
  payload,
})

const fetchUpcomingIeltsSuccess = (payload: Array<WorkshopSchema>) => ({
  type: UpcomingType.UPCOMING_IELTS_FETCH_SUCCESS,
  payload,
})

const fetchNudgeSuccess = (payload: any) => ({
  type: UpcomingType.NUDGE_FETCH_SUCCESS,
  payload,
})

const fetchUpcomingIeltsFail = (message: string) => ({
  type: UpcomingType.UPCOMING_IELTS_FETCH_FAIL,
  payload: message,
})

const toggleUpcomigIeltsBatchModal = (payload: boolean) => ({
  type: UpcomingType.TOGGLE_UPCOMING_IELTS_BATCHES_MODAL,
  payload,
})

const fetchUpcomingBatchPriceAndTime = () => ({
  type: UpcomingType.FETCH_GET_UPCOMING_BATCH_TIME_AND_PRICE,
})

const fetchUpcomingBatchPriceAndTimeSuccess = (
  payload: NextBatchPriceAndTiming,
) => ({
  type: UpcomingType.FETCH_GET_UPCOMING_BATCH_TIME_AND_PRICE_SUCCESS,
  payload,
})

const fetchIeltsBatches = (
  payload?: (allBatches: AllIeltsBatches | null) => void,
) => ({
  type: UpcomingType.FETCH_IELTS_BATCHES,
  payload,
})

const fetchPteBatches = (
  payload?: (allBatches: AllIeltsBatches | null) => void,
) => ({
  type: UpcomingType.FETCH_PTE_BATCHES,
  payload,
})
const setAllIeltsBatches = (payload: AllIeltsBatches | null) => ({
  type: UpcomingType.SET_ALL_IELTS_BATCHES,
  payload,
})

const fetchBannerData = () => ({
  type: UpcomingType.FETCH_BATCHES_BANNER,
})

const setBannerData = (payload: Array<BatchesBannerCarouselSchema> | null) => ({
  type: UpcomingType.SET_BATCHES_BANNER_DATA,
  payload,
})

const toggleOfferModal = (payload: boolean) => ({
  type: UpcomingType.TOGGLE_OFFER_MODAL,
  payload,
})

export const upcomingIeltsBatchesAction = {
  fetchUpcomingIeltsBatch,
  fetchUpcomingIeltsSuccess,
  fetchUpcomingIeltsFail,
  fetchUpcomingIeltsBatchClient,
  fetchNudgeSuccess,
  fetchIeltsFoundationBatchRequest,
  fetchIeltsFoundationBatchSuccess,
  fetchIeltsPlusBatchSuccess,
  toggleUpcomigIeltsBatchModal,
  fetchUpcomingBatchPriceAndTimeSuccess,
  fetchUpcomingBatchPriceAndTime,
  fetchIeltsBatches,
  setAllIeltsBatches,
  fetchBannerData,
  setBannerData,
  toggleOfferModal,
  fetchPteBatches,
}
