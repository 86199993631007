import { call, put, takeLatest } from "redux-saga/effects"
import { upcomingIeltsPlusBatchesApi } from "components/Ielts/UpcomingIeltsBatches/redux/UpcomingIeltsApi"
import {
  upcomingIeltsBatchesAction,
  UpcomingType,
} from "components/Ielts/UpcomingIeltsBatches/redux/UpcomingIeltsActions"
import { AnyAction } from "redux"
import { priceBundleName } from "DataMapper/Common/WorkshopData"
import { WorkshopSchema } from "Interfaces/reducerTypes/IeltsPlusSchema"
import { isFunction } from "utils/common_utils"

function* fetchData(action: AnyAction): Generator<any, any, any> {
  const res = yield call(
    upcomingIeltsPlusBatchesApi.fetchUpcomingIeltsPlusClass,
    action.payload,
  )
  if (res.success) {
    yield put(
      upcomingIeltsBatchesAction.fetchUpcomingIeltsSuccess(
        res.data.workshopBatches,
      ),
    )
    yield put(upcomingIeltsBatchesAction.fetchNudgeSuccess(res.data.nudgeOffer))
  } else {
    yield put(upcomingIeltsBatchesAction.fetchUpcomingIeltsFail(res.data))
  }
}

function* fetchDataClient(): Generator<any, any, any> {
  const res = yield call(
    upcomingIeltsPlusBatchesApi.fetchUpcomingIeltsPlusClassClientSide,
  )
  if (res.success) {
    const ieltsPlusBatch = res.data.workshopBatches.filter(
      (data: WorkshopSchema) =>
        data.pricingBundleName != priceBundleName.IELTS_FOUNDATION,
    )
    yield put(
      upcomingIeltsBatchesAction.fetchUpcomingIeltsSuccess(ieltsPlusBatch),
    )
    const foundationBatch = res.data.workshopBatches.filter(
      (data: WorkshopSchema) =>
        data.pricingBundleName == priceBundleName.IELTS_FOUNDATION,
    )
    yield put(
      upcomingIeltsBatchesAction.fetchIeltsFoundationBatchSuccess(
        foundationBatch,
      ),
    )
    const plusBatch = res.data.workshopBatches.filter(
      (data: WorkshopSchema) =>
        data.pricingBundleName == priceBundleName.IELTS_PLUS,
    )
    yield put(upcomingIeltsBatchesAction.fetchIeltsPlusBatchSuccess(plusBatch))
  } else {
    yield put(upcomingIeltsBatchesAction.fetchUpcomingIeltsFail(res.data))
  }
}
function* fetchUpcomingBatchPriceAndTimingSaga(): Generator<any, any, any> {
  try {
    const res = yield call(
      upcomingIeltsPlusBatchesApi.fetchUpcomingBatchPriceAndTime,
    )
    if (res.data.success) {
      yield put(
        upcomingIeltsBatchesAction.fetchUpcomingBatchPriceAndTimeSuccess(
          res.data.data,
        ),
      )
    } else {
      yield put(
        upcomingIeltsBatchesAction.fetchUpcomingIeltsFail(res.data.message),
      )
    }
  } catch (e) {
    yield put(
      upcomingIeltsBatchesAction.fetchUpcomingIeltsFail("Something went wrong"),
    )
  }
}

function* fetchIeltsBatches(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(upcomingIeltsPlusBatchesApi.fetchIeltsBatches)
    if (res.data.success) {
      if (isFunction(action.payload)) {
        action.payload(res.data.data)
      }
      yield put(upcomingIeltsBatchesAction.setAllIeltsBatches(res.data.data))
    } else {
      yield put(upcomingIeltsBatchesAction.setAllIeltsBatches(null))
    }
  } catch (e) {
    yield put(upcomingIeltsBatchesAction.setAllIeltsBatches(null))
  }
}
function* fetchPteBatches(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(upcomingIeltsPlusBatchesApi.fetchPteBatches)
    if (res.data.success) {
      if (isFunction(action.payload)) {
        action.payload(res.data.data)
      }
      yield put(upcomingIeltsBatchesAction.setAllIeltsBatches(res.data.data))
    } else {
      yield put(upcomingIeltsBatchesAction.setAllIeltsBatches(null))
    }
  } catch (e) {
    yield put(upcomingIeltsBatchesAction.setAllIeltsBatches(null))
  }
}

function* fetchBatchesBanner(): Generator<any, any, any> {
  try {
    const res = yield call(upcomingIeltsPlusBatchesApi.fetchIeltsBanner)
    if (res.data.success) {
      yield put(upcomingIeltsBatchesAction.setBannerData(res.data.data))
    } else {
      yield put(upcomingIeltsBatchesAction.setBannerData(null))
    }
  } catch (e) {
    yield put(upcomingIeltsBatchesAction.setBannerData(null))
  }
}

function* upcomingIeltsSaga() {
  yield takeLatest(UpcomingType.UPCOMING_IELTS_FETCH_REQUESTED, fetchData)
  yield takeLatest(
    UpcomingType.UPCOMING_IELTS_FETCH_CLIENT_REQUESTED,
    fetchDataClient,
  )
  yield takeLatest(
    UpcomingType.FETCH_GET_UPCOMING_BATCH_TIME_AND_PRICE,
    fetchUpcomingBatchPriceAndTimingSaga,
  )
  yield takeLatest(UpcomingType.FETCH_IELTS_BATCHES, fetchIeltsBatches)
  yield takeLatest(UpcomingType.FETCH_PTE_BATCHES, fetchPteBatches)
  yield takeLatest(UpcomingType.FETCH_BATCHES_BANNER, fetchBatchesBanner)
}

export default upcomingIeltsSaga
