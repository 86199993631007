import api from "service/api"
import { getCookie } from "utils/cookie-utiils";

export enum LOGIN_STEPS {
  GENERATE_OTP = "GENERATE_OTP",
  SUBMIT_OTP = "SUBMIT_OTP",
  RESEND_OTP = "RESEND_OTP",
  LOGIN_WITH_TC = "LOGIN_WITH_TC",
  VERFY_LOGIN_WITH_TC = "VERFY_LOGIN_WITH_TC",
  REGISTER_USER = "REGISTER_USER",
}

export function removeLeadingZeros(phoneNumber: string) {
  // Use a regular expression to match and remove leading zeros
  return phoneNumber.replace(/^0+/, "")
}

export const validateAndRedirectForIeltsForm = async (callbackUrl: string, isDownloadPage?: boolean) => {
  const openUrl = (url: string) => window.open(url, "_self");
  const decodedUrl = decodeURIComponent(callbackUrl);
  if (isDownloadPage || decodedUrl?.includes("mock-tests/band-predictor-test")) {
    try {
      const res = await api.get('/api/scholarRoute?path=ielts-elite/details');
      const { success, data, message } = res?.data || {};

      if (success) {
        const requiredFields = ["ieltsExamType", "whenIelts", "whatCareerStage", "name"];
        const isFormNotFilled = !data || requiredFields.some((field) => !data[field]);

        const redirectUrl: string = isFormNotFilled
          ? isDownloadPage
            ? `/ielts-seo-form/ielts_seo_blog_download?url=${encodeURIComponent(callbackUrl)}`
            : `/ielts-seo-form/ielts_seo_bpt?callbackUrl=${encodeURIComponent(callbackUrl)}`
          : callbackUrl || "";

        openUrl(redirectUrl);
      } else {
        throw new Error(message);
      }
    } catch (err: any) {
      console.error(err?.message || "Something went wrong. Please try after sometime");
      openUrl(callbackUrl || "");
    }
  } else {
    openUrl(callbackUrl || "");
  }
};

export const getPixelData = () => {
  const data = getUtmDataa();
  return { ...data, fbc: formatFbClid(data.fbclid, data._fbc) };
};

const getUtmDataa = () => {
  const urlParams = new URLSearchParams(window.location.search);

  return {
    utmSource: urlParams.get("utm_source"),
    utmCampaign: urlParams.get("utm_campaign"),
    adName: urlParams.get("ad_name"),
    utmMedium: urlParams.get("utm_medium"),
    utmTerm: urlParams.get("utm_term"),
    campaignType: urlParams.get("campaign_type"),
    adId: urlParams.get("ad_id"),
    fbclid: urlParams.get("fbclid"),
    _fbc: getCookie({},"_fbc"),
    _fbp: getCookie({},"_fbp"),
    gclid: urlParams.get("gclid"),
  };
};

const formatFbClid = (
  fbclid: string | null,
  fbc: string | null,
): string | null => {
  if (fbc !== null) {
    if (!isFbcCorrectFormat(fbc)) {
      console.log(`isFbcCorrectFormat false`);
      return null;
    }
    return fbc;
  }

  if (fbclid === null) return null;
  const version = "fb";
  const subdomainIndex = "1";
  const creationTime = Date.now();
  const formattedFbclid = `${version}.${subdomainIndex}.${creationTime}.${fbclid}`;
  return formattedFbclid;
};

const isFbcCorrectFormat = (fbc: string | null): boolean => {
  const substringArrays = fbc?.split(".");
  return (
    substringArrays?.length === 4 &&
    substringArrays[3] !== "" &&
    substringArrays[3] !== " "
  );
};