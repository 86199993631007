import { navRoutes } from "page_routes"

export enum SlotTypes {
  IELTS_SPOT_COUNSELLING = "IELTS_SPOT_COUNSELLING",
  PTE_SPOT_COUNSELLING = "PTE_SPOT_COUNSELLING",
}
export const getDashboardRoute = (event: SlotTypes): string => {
  switch (event) {
    case SlotTypes.IELTS_SPOT_COUNSELLING:
      return navRoutes.IELTS_STUDENT_DASHBOARD
    case SlotTypes.PTE_SPOT_COUNSELLING:
      return navRoutes.PTE_DASBOARD
  }
}

export const getExamType = (event: SlotTypes): "IELTS" | "PTE" => {
  switch (event) {
    case SlotTypes.IELTS_SPOT_COUNSELLING:
      return "IELTS"
    case SlotTypes.PTE_SPOT_COUNSELLING:
      return "PTE"
  }
}
