export const eventName = {
  NEW_IELTS_LEAD: "New Ielts Lead",
  NEW_PTE_LEAD: "New PTE Lead",
  EXPLORE_COURSES: "Explore Courses",
  SUBSCRIBE_MASTERCLASS: "Subscribe Masterclass",
  JOIN_TELEGRAM_COMMUNITY: "Join Telegram Community",
  SUBSCRIBE_TO_YOUTUBE: "Subscribe to Youtube",
  ADD_PAYMENT_INFO: "Add Payment Info",
  CONFIRM_SEAT: "Confirm Seat",
  DOWNLOAD_COURSE_OUTLINE: "Download Course Outline",
  RESERVE_SEAT: "Reserve Seat",
  ADD_TO_CART: "Add to Cart",
  CLICKED_ON_STUDY_MATERIAL: "Clicked on study material",
  CLICKED_ON_FS_STUDY_MATERIAL: "CLICKED_ON_FS_STUDY_MATERIAL",
  CLICKED_ON_FS_VIDEO: "CLICKED_ON_FS_VIDEO",
  CLICKED_ON_CLASS_RECORDINGS: "Clicked on Class Recordings",
  CLICKED_ON_BOOK_SPEAKING_CLUB: "Clicked on book speaking club",
  CLICKED_ON_BOOK_ESSAY_EVALUATION: "Clicked on book essay Evaluation",
  CLICKED_ON_CHAT_WITH_EDUCATOR: "Clicked on chat with Educator",
  PAID_FOR_IELTS_PLUS: "Paid for Ielts Plus",
  SPEAKING_CLUB_BOOK_ATTEMPTED: "speaking_slot_booking_attempted",
  INITIATE_CHECKOUT: "Initiate Checkout",
  JOIN_WAIT_LIST: "Join wait list",
  MASTERCLASS_REGISTERED: "Masterclass Registered",
  REDIRECT_PAGE_TRACKER: " redirect page tracker",
  JOIN_CLASS: "join class",
  UPCOMING_CLASS_CLICKED: "Upcoming Class Clicked",
  UPLOADED_ESSAY_FOR_EVALUATION: "Uploaded Essay For Evaluation",
  USER_PROFILE_PAGE_WITH_STAGE: "User on Profile Page in Stage",
  LS_PAYMENT_BUTTON_CLICK: "Click on LS payment button",
  LS_PAYMENT_SUCCESS: "LS Payment Success",
  LS_PAYMENT_FAIL_OR_ABANDONED: "LS Payment Fail",
  NEW_LS_LEAD: "New LS Lead",
  GENERAL_COUNSELLING_FORM_VISIBLE: "General counselling form visible",
  SPOT_COUNSELLING_FORM_VISIBLE: "Spot counselling form visible",
  GENERAL_COUNSELLING_FORM_SUBMIT: "General counselling form submit",
  SPOT_COUNSELLING_FORM_SUBMIT: "Spot Counselling form submit",
  GENERAL_COUNSELLING_MEETING_BOOKED: "General counselling meeting booked",
  SPOT_COUNSELLING_MEETING_BOOKED: "Spot Counselling meeting booked",
  FAST_TRACK_CLICK: "Fast_track_Click",
  INITIATE_CHECKOUT_FAST_TRACK: "initiate_checkout_fast_track",
  PAID_FOR_FAST_TRACK: "paid_for_fast_track",
  FS_VIDEO_CLICK_PLAY: "fs_video_click_play",
  CHANGE_USER_DETAIL: "change_user_detail",
  ADDED_TO_CALENDAR: "added_to_calendar",
  TIMER_JOIN_CLASS_CLICKED: "timer_join_class_clicked",
  NPS_SCORE_GIVEN: "nps_score_given",
  REMINDER_SET_MC: "reminder_set_MC",
  SPEAKING_SLOT_BOOKED: "speaking_slot_booked",
  MOCK_TEST_UNLOCKED: "mock_test_unlocked",
  MOCK_START_TEST: "mock_start_test",
  MOCK_TEST_SCORE_RELEASED: "mock_test_score_released",
  MOCK_WRITING_SUBMITTED: "mock_writing_submitted",
  MOCK_WRITING_EVALUATED: "mock_writing_evaluated",
  BATCH_CHANGE: "batch_change",
  VIEWED_CLASS_RECORDING: "viewed_class_recording",
  ONLINE_WORKSHOP_BATCH_ID: "online_workshop_batch_id",
  VALID_PROMO_CODE: "valid_promo_code",
  INVALID_PROMO_CODE: "invalid_promo_code",
  COURSE_LIKED: "course_liked",
  COURSE_DISLIKED: "course_disliked",
  PURCHASE_SPEAKING_SLOTS: "purchase_speaking_slots",
  CLICK_PURCHASE_SPEAKING_SLOTS: "click_purchase_speaking_slots",
  CLICK_ON_RESERVE_FREE_SEAT: "click_on_reserve_free_seat",
  MASTERCLASS_LANDING_PAGE: "masterclass_landing_page",
  CRASHCOURSE_LANDINGPAGE: "crashcourse_landingpage",
  VIDEO_SERIES_CLICK: "video_series_click",
  VIDEO_COMPLETE: "video_complete",
  CANCEL_SPEAKING_SLOT: "cancel_speaking_slot",
  START_LEARNING_FOLD1: "start_learning_fold1",
  START_LEARNING_FAST_TRACK: "start_learning_fast_track",
  START_LEARNING_PLUS: "start_learning_plus",
  START_LEARNING_PREMIUM: "start_learning_premium",
  VIEW_UPCOMING_BATCHES: "view_upcoming_batches",
  CLICKED_SHARE_DETAILS_IELTS_TEST: "clicked_share_details_ielts_test",
  SUBMITTED_IELTS_TEST_DATE: "submitted_ielts_test_date",
  VIDEO_SERIES_PREVIEW_CLICK: "video_series_preview_click",
  VIDEO_PREVIEW_START: "video_preview_start",
  VIDEO_PREVIEW_COMPLETE: "video_preview_complete",
}

export const gaEvents = {
  IELTS_ADD_PAYMENT_INFO: "ielts_add_payment_info",
  IELTS_ADD_TO_CART: "ielts_add_to_cart",
}

export const eventCategory = {
  IELTS_WEBSITE: "Ielts Website",
  IELTS_PROFILE: "Ielts Profile",
  NEW_IELTS_LEAD: "New Ielts Lead",
  NEW_PTE_LEAD: "New PTE Lead",
  USER_PROFILE_PAGE: "Profile page",
  COUNTRY_LANDING_PAGE: "Country Landing Page",
  LS_PAYMENT: "LS Payment",
  NEW_LEAD: "New Lead",
  COUNSELLING_TYPE: "Counselling type",
  FAST_TRACK: "fast_track",
  NPS_FORM: "nps_form",
  SPEAKING_CLUB: "speaking_club",
  MOCK_TEST: "mock_test",
  STUDY_MATERIAL: "study_material",
  REGISTRATION: "registration",
  PAID_FOR_IELTS_PLUS: "Paid for Ielts pro",
  PAID_FOR_PTE_PRO: "Paid for pte pro",
  IELTS_CRASH_COURSE_WEBSITE: "ielts_crash_course_website",
}

export const userAttributeName = {
  WE_EMAIL: "we_email",
  WE_HASHED_EMAIL: "we_hashed_email",
  WE_FIRST_NAME: "we_first_name",
  WE_LAST_NAME: "we_last_name",
  WE_PHONE: "we_phone",
  WE_WHATSAPP_OPT_IN: "we_whatsapp_opt_in",
}

//KEEP ALL THE DATA THAT WE PUSH TO WEBENGAGE HERE , DON'T USE DIRECTLY AS STRING
export const WEeventDataAttributeNames = {
  CATEGORY: "category",
  ACTION: "action",
  LABEL: "label",
  USER_STAGE: "user_stage",
  COUNTRY: "country",
  PREFERRED_INTAKE: "preferred_intake",
  PREFERRED_PROGRAM: "preferred_program",
  ADMIT_STATUS: "admit_status",
  HELP_FROM_LS: "help_from_ls",
  PASSPORT_STATUS: "passport_status",
  IELTS_STATUS: "ielts_status",
  HIGHEST_EDUCATION: "highest_education",
  UTM_SOURCE: "utmSource",
  UTM_CAMPAIGN: "utmCampaign",
  MEETING_LINK: "meeting_link",
  MEETING_DATE: "meeting_date",
  MEETING_TIME: "meeting_time",
  USER_NAME: "user_name",
  USER_EMAIL: "user_email",
  PRICE_OFFER: "price_offer",
  PAID: "paid",
  VIDEO_TITLE: "video_title",
  TEACHER_NAME: "teacher_name",
  BATCH_ID: "batchId",
  BOOK_DATE: "book_date",
  WEEKLY_SCHEDULE: "weekly_schedule",
  TELEGRAM_LINK: "TELEGRAM_LINK",
  BATCH_END_DATE: "batch_end_date",
  BATCH_START_DATE: "batch_start_date",
  NPS_RATING: "nps_rating",
  WORKSHOP_TYPE: "workshop_type",
  BATCH_TITLE: "BATCH_TITLE",
  SPEAKING_CLUB_SLOT_BOOKED: "speaking_club_slot_booked",
  SPEAKING_BATCH_ID: "speaking_batch_id",
  IELTS_PLUS_BATCH_ID: "ielts_plus_batch_id",
  MOCK_TEST_SECTION: "mock_test_section",
  MOCK_TEST_ID: "mock_test_id",
  MOCK_TEST_INDEX: "mock_test_index",
  STUDY_MATERIAL_LINK: "study_material_link",
  PROMO_CODE: "promo_code",
  USER_ID: "user_id",
  SERIES_TITLE: "series_title",
  BATCH_START_TIME: "batch_start_time",
  TYPE: "type",
  IELTS_DATE: "ielts_date",
  TEACHER_TELEGRAM_URL: "teacher_telegram_url",
}
