import api from "service/api"
import { initRazorpayScript } from "razorpayScript"

interface RazorpayPaymentHandler {
  paymentType: string
  formDescription: string
  currency?: string
}
//configure standard checkout form - only form view configurations here
const configurePaymentCheckoutForm = (
  formDescription: string,
  currency = "INR",
) => {
  const options = {
    key: process.env.RAZORPAY_CLIENT_KEY,
    currency,
    name: "LeapScholar",
    description: formDescription || "",
    image: `${process.env.CDN_URL}/assets/images/header/leap-logo.svg`,
  }
  return options
}

export const initRazorpayPaymentRequest = (props: RazorpayPaymentHandler) => {
  initRazorpayScript()
  return new Promise((res) => {
    const { paymentType, formDescription, currency } = props
    const options = configurePaymentCheckoutForm(formDescription, currency)

    api
      .post(`/api/scholarRoute?path=payment/createOrder`, {
        paymentType,
      })
      .then((_o) => {
        const { data, success } = _o.data
        const { orderId, correlationId, payer, alreadyPaid } = data || {}
        const { name, email, phone } = payer || {}
        if (!success) res(false)
        else {
          if (!alreadyPaid && orderId) {
            //@ts-ignore
            options["order_id"] = orderId
            //@ts-ignore
            // options["amount"] = 999; // not needed as cominf from backend

            //@ts-ignore
            options["prefill"] = {
              name: name || "",
              email: email || "",
              contact: phone || "",
            }
            // debugger;
            //@ts-ignore
            options["handler"] = async function () {
              // note : triggers only on payment success, not when payment fails
              const _t = await api(
                `/api/scholarRoute?path=payment/confirm/${correlationId}`,
              )
              // debugger;
              //@ts-ignore
              const { data } = _t.data
              if (data) {
                res(data)
              }
            }
            //@ts-ignore
            options["modal"] = {
              // triggers only when modal is closed explicitly
              ondismiss: async function () {
                //fail
                const _t = await api.get(
                  `/api/scholarRoute?path=payment/confirm/${correlationId}`,
                )
                // debugger;
                const { data } = _t.data
                res(data)
              },
            }
            //@ts-ignore
            const razorpay = new Razorpay(options) // call razorpay sdk
            razorpay.open()

            //to capture failed payments
            razorpay.on("payment.error", async function () {
              // debugger;
              const _t = await api.get(
                `/api/scholarRoute?path=payment/confirm/${correlationId}`,
              )
              const { data } = _t.data
              res(data)
            })
          } else {
            res(true)
          }
        }
      })
      .catch((err) => {
        console.log(err)
        res(false)
      })
  })
}

interface PaymentInfo {
  paymentType: string
  formDescription: string
}

interface RazorpayOptions {
  key?: string
  currency: string
  name: string
  description: string
  image: string
  order_id?: string
  prefill?: {
    name: string
    email: string
    contact: string
  }
  handler?: (res: any) => any
  modal?: {
    ondismiss: (reason: any) => any
  }
}

export const initPaymentRequest = (
  requestBody: any,
  paymentInfo: PaymentInfo,
  batchId: string,
) => {
  initRazorpayScript()
  return new Promise((resolve) => {
    const { paymentType, formDescription } = paymentInfo
    const options: RazorpayOptions = configurePaymentCheckoutForm(
      formDescription,
      requestBody?.currency,
    )
    requestBody.paymentType = paymentType
    requestBody.batchId = batchId
    api
      .post(`/api/scholarRoute?path=v2/payment/createOrder`, {
        ...requestBody,
      })
      .then((res) => {
        const { success, data } = res.data
        if (success) {
          const { orderId, correlationId, payer, alreadyPaid } = data || {}
          const { name, email, phone } = payer || {}
          if (!alreadyPaid && orderId) {
            options["order_id"] = orderId
            options["prefill"] = {
              name: name || "",
              email: email || "",
              contact: phone || "",
            }
            options["handler"] = async function () {
              // note : triggers only on payment success, not when payment fails
              const paymentSuccessRes = await api(
                `/api/scholarRoute?path=payment/confirm/${correlationId}`,
              )
              const { data } = paymentSuccessRes.data
              if (data) {
                resolve(data)
              }
            }
            options["modal"] = {
              ondismiss: async function () {
                //fail
                const paymentFailRes = await api.get(
                  `/api/scholarRoute?path=payment/confirm/${correlationId}`,
                )
                const { data } = paymentFailRes.data
                resolve(data)
              },
            }
            //@ts-ignore
            const razorpay = new Razorpay(options)
            razorpay.open()
            razorpay.on("payment.error", async function () {
              const modalCloseRes = await api.get(
                `/api/scholarRoute?path=payment/confirm/${correlationId}`,
              )
              const { data } = modalCloseRes.data
              resolve(data)
            })
          } else {
            resolve(true)
          }
        }
      })
      .catch((err) => {
        console.log(err)
        resolve(false)
      })
  })
}

export const initPaymentRequestForCounselling = (
  paymentInfo: any,
  requestBody: any,
) => {
  initRazorpayScript()
  return new Promise((resolve) => {
    const options: RazorpayOptions = configurePaymentCheckoutForm(
      paymentInfo?.formDescription,
      paymentInfo?.currency,
    )
    api
      .post(`/api/scholarRoute?path=v2/payment/createOrder`, {
        ...requestBody,
      })
      .then((res) => {
        const { success, data } = res.data
        if (success) {
          const { orderId, correlationId, payer, alreadyPaid } = data || {}
          const { name, email, phone } = payer || {}
          if (!alreadyPaid && orderId) {
            options["order_id"] = orderId
            options["prefill"] = {
              name: name || "",
              email: email || "",
              contact: phone || "",
            }
            options["handler"] = async function () {
              // note : triggers only on payment success, not when payment fails
              const paymentSuccessRes = await api(
                `/api/scholarRoute?path=payment/confirm/${correlationId}`,
              )
              const { data } = paymentSuccessRes.data
              if (data) {
                resolve(data)
              }
            }
            options["modal"] = {
              ondismiss: async function (reason) {

                //fail
                const paymentFailRes = await api.get(
                  `/api/scholarRoute?path=payment/confirm/${correlationId}`,
                )
                if (!reason) {  
                    resolve(false)
                }
              },
            }

            //@ts-ignore
            const razorpay = new window.Razorpay(options)
            razorpay.open()
            razorpay.on("payment.error", async function () {
              const modalCloseRes = await api.get(
                `/api/scholarRoute?path=payment/confirm/${correlationId}`,
              )
            })
          } else {
            resolve(true)
          }
        }
      })
      .catch((err) => {
        console.log(err)
        resolve(false)
      })
  })
}

export const initPaymentRequestV2 = (
  requestBody: any,
  data: any,
  formDescription: string,
) => {
  return new Promise((resolve) => {
    const options: RazorpayOptions = configurePaymentCheckoutForm(
      formDescription,
      requestBody?.currency,
    )
    const { orderId, correlationId, payer, alreadyPaid } = data || {}
    const { name, email, phone } = payer || {}
    if (!alreadyPaid && orderId) {
      options["order_id"] = orderId
      options["prefill"] = {
        name: name || "",
        email: email || "",
        contact: phone || "",
      }
      options["handler"] = async function () {
        // note : triggers only on payment success, not when payment fails
        const paymentSuccessRes = await api(
          `/api/scholarRoute?path=payment/confirm/${correlationId}`,
        )
        const { data } = paymentSuccessRes.data
        if (data) {
          resolve(data)
        }
      }
      options["modal"] = {
        ondismiss: async function () {
          //fail
          const paymentFailRes = await api.get(
            `/api/scholarRoute?path=payment/confirm/${correlationId}`,
          )
          const { data } = paymentFailRes.data
          resolve(data)
        },
      }
      //@ts-ignore
      const razorpay = new Razorpay(options)
      razorpay.open()
      razorpay.on("payment.error", async function () {
        const modalCloseRes = await api.get(
          `/api/scholarRoute?path=payment/confirm/${correlationId}`,
        )
        const { data } = modalCloseRes.data
        resolve(data)
      })
    } else {
      resolve(true)
    }
  })
}

export const initRazorpayPaymentRequestV2 = (
  props: RazorpayPaymentHandler,
  data: any,
): any => {
  return new Promise((res) => {
    const { formDescription, currency } = props
    const options = configurePaymentCheckoutForm(formDescription, currency)
    const { orderId, correlationId, payer, alreadyPaid } = data || {}
    const { name, email, phone } = payer || {}
    if (!alreadyPaid && orderId) {
      //@ts-ignore
      options["order_id"] = orderId
      //@ts-ignore
      // options["amount"] = 999; // not needed as cominf from backend

      //@ts-ignore
      options["prefill"] = {
        name: name || "",
        email: email || "",
        contact: phone || "",
      }
      // debugger;
      //@ts-ignore
      options["handler"] = async function () {
        const _t = await api(
          `/api/scholarRoute?path=payment/confirm/${correlationId}`,
        )
        // debugger;
        //@ts-ignore
        const { data } = _t.data
        if (data) {
          res(data)
        }
      }
      //@ts-ignore
      options["modal"] = {
        // triggers only when modal is closed explicitly
        ondismiss: async function () {
          //fail
          const _t = await api.get(
            `/api/scholarRoute?path=payment/confirm/${correlationId}`,
          )
          // debugger;
          const { data } = _t.data
          res(data)
        },
      }
      //@ts-ignore
      const razorpay = new Razorpay(options) // call razorpay sdk
      razorpay.open()

      //to capture failed payments
      razorpay.on("payment.error", async function () {
        // debugger;
        const _t = await api.get(
          `/api/scholarRoute?path=payment/confirm/${correlationId}`,
        )
        const { data } = _t.data
        res(data)
      })
    } else {
      res(true)
    }
  })
}
export type GenericBundlePaymentReturnType = {
  scholarPaymentOrderId: string
  amount: number
  isSuccess: boolean
}
export const initRazorpayPaymentGenericBundle = (
  props: any,
  data: any,
  toggleLoader?: (status: boolean) => void,
): Promise<GenericBundlePaymentReturnType | boolean> => {
  return new Promise((res) => {
    const { formDescription, currency } = props
    const options = configurePaymentCheckoutForm(formDescription, currency)
    const { orderId, correlationId, payer, alreadyPaid } = data || {}
    const { name, email, phone } = payer || {}
    if (!alreadyPaid && orderId) {
      //@ts-ignore
      options["order_id"] = orderId
      //@ts-ignore
      // options["amount"] = 999; // not needed as cominf from backend

      //@ts-ignore
      options["prefill"] = {
        name: name || "",
        email: email || "",
        contact: phone || "",
      }
      // debugger;
      //@ts-ignore
      options["handler"] = async function () {
        toggleLoader && toggleLoader(true)
        const _t = await api(
          `/api/scholarRoute?path=payment/confirm/${correlationId}/bundle/${props.bundleName}`,
        )
        // debugger;
        //@ts-ignore
        const { data } = _t.data
        toggleLoader && toggleLoader(false)
        if (data) {
          res(data)
        }
      }
      //@ts-ignore
      options["modal"] = {
        // triggers only when modal is closed explicitly
        ondismiss: async function () {
          //fail
          const _t = await api.get(
            `/api/scholarRoute?path=payment/confirm/${correlationId}/bundle/${props.bundleName}`,
          )
          // debugger;
          const { data } = _t.data
          res(data)
        },
      }
      //@ts-ignore
      const razorpay = new Razorpay(options) // call razorpay sdk
      razorpay.open()

      //to capture failed payments
      razorpay.on("payment.error", async function () {
        // debugger;
        const _t = await api.get(
          `/api/scholarRoute?path=payment/confirm/${correlationId}/bundle/${props.bundleName}`,
        )
        const { data } = _t.data
        res(data)
      })
    } else {
      res(true)
    }
  })
}
