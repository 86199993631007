import { ValidationPayload } from "components/Ielts/PromoCode"

const fetchPromoCodeValidation = (payload: ValidationPayload) => ({
  type: "FETCH_PROMO_CODE_VALIDATION",
  payload,
})

const fetchPromoCodeValidationSuccess = (payload: any) => ({
  type: "FETCH_PROMO_CODE_VALIDATION_SUCCESS",
  payload,
})

const fetchPromoCodeValidationFail = (message: string) => ({
  type: "FETCH_PROMO_CODE_VALIDATION_FAIL",
  payload: message,
})

const updatePromoCodeApplied = (payload: boolean) => ({
  type: "UPDATE_PROMO_CODE_APPLIED",
  payload,
})

const removePromoCode = () => ({
  type: "REMOVE_PROMO_CODE",
})

const togglePromoCodeForm = (payload: boolean) => ({
  type: "TOGGLE_PROMO_CODE_FORM",
  payload,
})

const togglePromoCodeApplicationLoading = (payload: boolean) => ({
  type: "TOGGLE_PROMO_CODE_APPLICATION_LOADING",
  payload,
})

const fetchPromoCodes = () => ({
  type: "FETCH_PROMO_CODES",
})
const changeIsValidCode = (payload: boolean | null) => ({
  type: "CHANGE_IS_VALID_CODE",
  payload,
})

const fetchPromoCodesSuccess = (payload: any) => ({
  type: "FETCH_PROMO_CODES_SUCCESS",
  payload,
})

const fetchPromoCodesFail = (message: string) => ({
  type: "FETCH_PROMO_CODES_FAIL",
  payload: message,
})

export const promoCodeActions = {
  fetchPromoCodeValidation,
  fetchPromoCodeValidationSuccess,
  fetchPromoCodeValidationFail,
  updatePromoCodeApplied,
  removePromoCode,
  togglePromoCodeForm,
  togglePromoCodeApplicationLoading,
  fetchPromoCodes,
  fetchPromoCodesSuccess,
  fetchPromoCodesFail,
  changeIsValidCode,
}
