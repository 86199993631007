import { counsellingStageRank } from "DataMapper/Profile/StageMapper"
import { referAndEarnActions } from "components/Profile/ReferAndEarn/redux/ReferAndEarnActions"
import InternetCheckAlertBox from "components/common/InternetCheckAlertBox"
import { AuthProps } from "components/decorator/WithAuth"
import dynamic from "next/dynamic"
import React, { Dispatch, FunctionComponent, useEffect, useState } from "react"
import { connect } from "react-redux"
import { Action } from "redux"
import { checkIfMENAUser } from "utils/country_utils"
import useIsomorphicEffect from "hooks/useIsomorphicEffect"
import useLocation from "hooks/login/useLocation"
import { CounselingStage } from "apollo/model/profile/profile"
const NeedHelpV1 = dynamic(() => import("components/NeedHelpV1/NeedHelpV1"))
const MenaLogoutNavbar = dynamic(() => import("components/common/MenaNavbar"))
import Footer from "./Footer"
import NavbarWrapper from "components/common/NavbarWrapper"

interface LayoutProps extends AuthProps {
  children: React.ReactNode
  showConnect?: boolean
  handleConnectClick?: Function
  isHeaderHidden?: boolean
  isFooterHidden?: boolean
  customNavbar?: React.ReactNode
  showCustomNavbar?: boolean
  haveBottomPadding?: boolean
  counsellingStage: string
  fetchUserInfoData: Function
  userInfo: any
  shouldShowBackButton?: boolean
}

const Layout: FunctionComponent<LayoutProps> = ({
  auth,
  children,
  isHeaderHidden = false,
  isFooterHidden = false,
  counsellingStage,
  fetchUserInfoData,
  userInfo,
  shouldShowBackButton = false,
  customNavbar = null,
}) => {
  const [showNeedHelpModal, setShowNeedHelpModal] = useState<boolean>(false)
  const [footerVisiblity, setFooterVisiblity] = useState(!isFooterHidden)
  const [headerVisibility, setHeaderVisibility] = useState(!isHeaderHidden)
  const [isMeUser, setIsMeUser] = useState<boolean>(false)
  const { userCountry } = useLocation()
  const loggedIn = auth?.isValid
  const isPaymentDoneUser = counsellingStage
    ? counsellingStageRank[counsellingStage] >=
      counsellingStageRank[CounselingStage.LS_PAYMENT_DONE]
    : false

  const isomorphicEffect = useIsomorphicEffect()

  isomorphicEffect(() => {
    if (checkIfMENAUser(userCountry)) {
      setIsMeUser(true)
    }
  }, [userCountry])

  useEffect(() => {
    if (showNeedHelpModal) {
      fetchUserInfoData()
    }
  }, [showNeedHelpModal])

  useEffect(() => {
    try {
      const urlSearchParams = new URLSearchParams(window.location.search)
      if (urlSearchParams.get("isFooterHidden") === "true") {
        setFooterVisiblity(false)
      }
      if (urlSearchParams.get("isHeaderHidden") === "true") {
        setHeaderVisibility(false)
      }
    } catch (error) {}
  }, [])

  const checkHistory = () => {
    if (window) {
      const history = window.history
      if (history.length > 2) window.history.back()
    }
  }

  const isFooterVisible = () => {
    if (isMeUser) return false
    return !loggedIn || counsellingStageRank[counsellingStage] < 10
  }

  return (
    <>
      {headerVisibility && (
        <>
          {isMeUser ? (
            <MenaLogoutNavbar />
          ) : customNavbar ? (
            customNavbar
          ) : (
            <NavbarWrapper />
          )}
          {showNeedHelpModal && (
            <NeedHelpV1
              setShowNeedHelp={setShowNeedHelpModal}
              userInfo={userInfo}
            />
          )}
        </>
      )}
      {shouldShowBackButton && (
        <img
          src={`${process.env.CDN_URL}/assets/icons/chat/back_button_dark.svg`}
          alt="back"
          className="mt-4 ml-4"
          onClick={() => {
            checkHistory()
          }}
        />
      )}
      <InternetCheckAlertBox />
      {children}

      {!isFooterVisible() || !footerVisiblity ? null : <Footer />}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  auth: state.auth.auth,
  counsellingStage: state.spotCounselling.stage,
  userInfo: state.referAndEarn.userInfo,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  fetchUserInfoData: () => {
    dispatch(referAndEarnActions.fetchUserInfo())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
