import { call, put, takeLatest } from "redux-saga/effects"
import { IeltsDemoApi } from "components/Ielts/IeltsDemo/redux/IeltsDemoApi"
import {
  bookIeltsSlot,
  setError,
  setHasNoSlots,
  setIeltsSlotsDetails,
  setHasBookedSlot,
  setIsLoading,
  fetchMasterclassData,
  setMasterClassListObj,
  enrollWorkshop,
  subscribeMasterClass,
  setMasterClassBooked,
  fetchRejoinModalData,
  setRejoinDemoModalData,
  fetchAnroidAppMagicLinkSlice,
  setAnroidAppMagicLink,
  bookPteSlot,
} from "components/Ielts/IeltsDemo/redux/IeltsDemoSlice"
import { fetchIeltsSlots } from "components/Ielts/IeltsDemo/redux/IeltsDemoSlice"
import {
  confirmIeltsEliteDemoSlot,
  confirmPteEliteDemoSlot,
} from "components/SpotCounselling/Calendar/service"
import { AnyAction } from "redux"
import { trackEventAsync, trackUserDevice } from "scripts/segment"
import { isFunction } from "utils/common_utils"
import { getUserDeviceName } from "utils/browserUtils"
import { masterclassActions } from "components/Profile/IeltsProfile/Masterclass/redux/MasterclassActions"

function* fetchIeltsDemoSlots(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      IeltsDemoApi.fetchIeltsEliteDemoSlots,
      action.payload,
    )
    yield put(setIsLoading(false))
    if (res.data.success) {
      yield put(setIeltsSlotsDetails(res.data.data))
    } else {
      yield put(setError(true))
      yield put(setIeltsSlotsDetails(null))
    }
  } catch (e) {
    yield put(setIsLoading(false))
    yield put(setError(true))
    yield put(setIeltsSlotsDetails(null))
  }
}

function* bookIeltsSlotSaga(Action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      confirmIeltsEliteDemoSlot,
      Action.payload.payload,
      Action.payload.isReschedule,
    )
    if (res.success) {
      yield call(trackUserDevice)
      yield trackEventAsync("Event Booked", {
        ...Action.payload.segmentPayload,
      })
      if (isFunction(Action.payload.postBookingCallBack)) {
        Action.payload.postBookingCallBack()
      } else {
        yield put(setHasBookedSlot(res.data))
      }
    } else {
      yield put(setError(true))
      yield put(setHasBookedSlot(false))
    }
  } catch (e) {
    yield put(setError(true))
    yield put(setHasBookedSlot(false))
  }
}
function* bookPteSlotSaga(Action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      confirmPteEliteDemoSlot,
      Action.payload.payload,
      Action.payload.isReschedule,
    )
    if (res.success) {
      yield call(trackUserDevice)
      yield trackEventAsync("Event Booked", {
        ...Action.payload.segmentPayload,
      })
      if (isFunction(Action.payload.postBookingCallBack)) {
        Action.payload.postBookingCallBack()
      } else {
        yield put(setHasBookedSlot(res.data))
      }
    } else {
      yield put(setError(true))
      yield put(setHasBookedSlot(false))
    }
  } catch (e) {
    yield put(setError(true))
    yield put(setHasBookedSlot(false))
  }
}

function* fetchMasterclassDataSaga(
  action: AnyAction,
): Generator<any, any, any> {
  try {
    const res = yield call(IeltsDemoApi.fetchMasterclassDataApi, action.payload)
    if (res.data.success) {
      yield put(setMasterClassListObj(res.data.data))
    } else {
      yield put(setError(true))
    }
  } catch (e) {
    yield put(setError(true))
  } finally {
    yield put(setIsLoading(false))
  }
}

function* enrollWorkshopSaga(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(IeltsDemoApi.enrollWorkshop, action.payload)
    if (res.data.success) {
      yield put(setMasterClassBooked(res.data.data))
    } else {
      yield put(setError(true))
    }
  } catch (e) {
    yield put(setError(true))
  }
}

function* subscribeMasterClassSaga(
  action: AnyAction,
): Generator<any, any, any> {
  try {
    const res = yield call(IeltsDemoApi.subscribeMasterClassApi)
    if (res.data.success) {
      if (res.data.data) {
        yield put(
          masterclassActions.fetchMasterclassSubscriptionStatusSuccess(
            res.data.data,
          ),
        )
        if (isFunction(action.payload)) {
          yield action.payload()
        }
      }
    } else {
      yield put(setError(true))
    }
  } catch (e) {
    yield put(setError(true))
  }
}

function* fetchRejoinModalDataSaga(): Generator<any, any, any> {
  try {
    const res = yield call(IeltsDemoApi.fetchRejoinModalStatus)
    if (res.data.success) {
      yield put(setRejoinDemoModalData(res.data.data))
    } else {
      yield put(setRejoinDemoModalData(null))
    }
  } catch (e) {
    yield put(setRejoinDemoModalData(null))
  }
}

function* fetchAnroidAppMagicLinkSaga(): Generator<any, any, any> {
  try {
    const res = yield call(IeltsDemoApi.fetchAnroidAppMagicLink)
    if (res.data.success) {
      yield put(setAnroidAppMagicLink(res.data.data))
    } else {
      yield put(setAnroidAppMagicLink(null))
    }
  } catch (e) {
    yield put(setAnroidAppMagicLink(null))
  }
}

function* ieltsDemoSaga() {
  yield takeLatest(fetchIeltsSlots, fetchIeltsDemoSlots)
  yield takeLatest(bookIeltsSlot, bookIeltsSlotSaga)
  yield takeLatest(bookPteSlot, bookPteSlotSaga)
  yield takeLatest(fetchMasterclassData, fetchMasterclassDataSaga)
  yield takeLatest(enrollWorkshop, enrollWorkshopSaga)
  yield takeLatest(subscribeMasterClass, subscribeMasterClassSaga)
  yield takeLatest(fetchRejoinModalData, fetchRejoinModalDataSaga)
  yield takeLatest(fetchAnroidAppMagicLinkSlice, fetchAnroidAppMagicLinkSaga)
}

export default ieltsDemoSaga
