import { takeLatest, call, put } from "@redux-saga/core/effects"
import { AnyAction } from "redux"
import { promoCodeActions } from "components/Ielts/PromoCode/redux/PromoCodeActions"
import { promoCodeApi } from "components/Ielts/PromoCode/redux/PromoCodeApi"
import { eventTracker } from "eventTracker"
import {
  eventName,
  WEeventDataAttributeNames,
} from "DataMapper/EventTracker/EventData"
import { trackIeltsClick } from "scripts/segment"
import { getBatchType, ieltsPageName } from "utils/events-utils"

function* fetchPromoCodeValidation(
  action: AnyAction,
): Generator<any, any, any> {
  const { batchData } = action.payload
  yield put(promoCodeActions.togglePromoCodeApplicationLoading(true))
  const clickEvent = async (price: any) => {
    if (batchData)
      trackIeltsClick(ieltsPageName.IELTS_COURSE_DESCRIPTION_PAGE, {
        widgetName: "Promo Code",
        widgetFormat: "Field",
        contentName: "Apply",
        contentFormat: "Button",
        eventId: batchData?.id,
        eventCategory: getBatchType(batchData),
        offerPrice: price,
      })
  }
  try {
    const res = yield call(
      promoCodeApi.fetchPromoCodeValidation,
      action.payload,
    )

    if (res.success) {
      const payload = {
        data: res.data,
        offerPrice: action.payload.offerPrice,
      }
      yield put(promoCodeActions.fetchPromoCodeValidationSuccess(payload))
      const price =
        payload.offerPrice - (payload.data.discount / 100) * payload.offerPrice
      yield call(clickEvent, price)
      const { VALID_PROMO_CODE } = eventName
      yield call(
        eventTracker,
        VALID_PROMO_CODE,
        {
          CATEGORY: "",
          ACTION: "",
          LABEL: "",
          [WEeventDataAttributeNames.PROMO_CODE]: action.payload.promoCode,
        },
        false,
      )
    } else {
      const { INVALID_PROMO_CODE } = eventName
      yield put(
        promoCodeActions.fetchPromoCodeValidationFail(
          res.message || "Invalid code. Try the code LEAPIELTS to get 10% off",
        ),
      )
      yield call(
        eventTracker,
        INVALID_PROMO_CODE,
        {
          CATEGORY: "",
          ACTION: "",
          LABEL: "",
          [WEeventDataAttributeNames.PROMO_CODE]: action.payload.promoCode,
        },
        false,
      )
      yield call(
        clickEvent,
        batchData?.nudgeOffer?.show
          ? batchData?.nudgeOffer?.discountedOfferPrice
          : batchData?.offerPrice,
      )
    }
    yield put(promoCodeActions.togglePromoCodeApplicationLoading(false))
  } catch (e: any) {
    yield put(
      promoCodeActions.fetchPromoCodeValidationFail(
        "Something went wrong. Please try after sometime",
      ),
    )
    yield call(
      clickEvent,
      batchData?.nudgeOffer?.show
        ? batchData?.nudgeOffer?.discountedOfferPrice
        : batchData?.offerPrice,
    )
    yield put(promoCodeActions.togglePromoCodeApplicationLoading(false))
  }
}

function* fetchPromoCodes(): Generator<any, any, any> {
  try {
    const res = yield call(promoCodeApi.fetchPromoCodes)
    if (res.success) {
      yield put(promoCodeActions.fetchPromoCodesSuccess(res.data))
    } else {
      yield put(promoCodeActions.fetchPromoCodesFail(res.message))
    }
  } catch (e: any) {
    yield put(promoCodeActions.fetchPromoCodesFail(e.message))
  }
}

function* promoCodeSaga() {
  yield takeLatest("FETCH_PROMO_CODE_VALIDATION", fetchPromoCodeValidation)
  yield takeLatest("FETCH_PROMO_CODES", fetchPromoCodes)
}

export default promoCodeSaga
